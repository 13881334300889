div .navBar {
    width: 100px;
    background-color: var(--primary-color);
    color: var(--white-color);
    justify-content: flex-start;
    flex-direction: column;
}

div .navBarMobile {
    background-color: var(--primary-dark-color);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.dash-container {
    display: flex;
    grid-template-columns: 100px 1fr;
    height: 100vh;
}

@media (max-width: 769px) {
    div .logo {
        height: 20px;
    }

    .dash-container {
        grid-template-columns: 100% 1fr;
    }
}
