.wizard-background {
    --min-viewport-height: 800px; /* Defina aqui a altura mínima desejada */
    background-color: var(--primary-color) !important;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.wizard-background::before,
.wizard-background::after {
    --offset-y: -90px;
    --largura-x: 200%;
    content: "";
    /* background: url(/public/wizard_silhueta_new.svg) no-repeat left; Fixa a ancoragem à esquerda */
    position: absolute;
    width: 100%;
    left: 0;
}

.wizard-background::before {
    top: 0;
    height: 250px;
    background-position: -80px top;
    background-size: var(--largura-x) 300px;
    clip-path: inset(0 0 70% 0);
}

.wizard-background::after {
    height: 250px;
    background-position: -80px calc(100% + var(--offset-y)); /* Alinha com a parte inferior */
    background-size: var(--largura-x) 350px;
    position: absolute;
    bottom: 0;
    min-height: 250px;
    transform: translateY(var(--translate-y, 0));
    --translate-y: max(0px, calc(max(var(--content-height, 100vh), var(--min-viewport-height)) - 100vh));
}

/* Desktop styles (> 700px) */
@media screen and (min-width: 780px) {
    .wizard-background::before,
    .wizard-background::after {
        --largura-x: 200%;
    }

    .wizard-background::before {
        height: 450px;
        background-position: -200px -50px;
        background-size: var(--largura-x) 700px;
        clip-path: inset(0 0 70% 0);
    }

    .wizard-background::after {
        height: 350px;
        background-position: -80px calc(100%);
        background-size: var(--largura-x) 500px;
    }
}
